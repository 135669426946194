import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import MobileNav from './MobileNav';
import IndexNav from './IndexNav';

const HomeHeader = styled.header`
	display: flex;
	justify-content: space-between;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background-color: transparent;
	z-index: 10;
	color: #f9fdf7;

	a {
		color: inherit;
		text-decoration: none;
	}

	@media (min-width: 1024px) {
		font-size: 1.3333333333em;
	}
`;

const Header = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	color: #131313;
	border-bottom: 1px solid #131313;
	background-color: #f9fdf7;
	z-index: 10;

	a {
		color: inherit;
		text-decoration: none;
	}

	@media (min-width: 1024px) {
		font-size: 1.3333333333em;
	}
`;

const Nav = styled.nav`
	display: none;
	@media (min-width: 1024px) {
		display: flex;
		justify-content: flex-end;
		padding: 20px;
		border-left: ${props => (props.home ? '1px solid transparent' : '1px solid')};
		text-align: right;
		width: 50%;
		padding: 45px 50px;
	}
`;

const TitleLink = styled(Link)`
	padding: 20px;
	@media (min-width: 1024px) {
		padding: 45px 50px;
	}
`;

const NavLink = styled(Link)`
	@media (min-width: 1024px) {
		&.active {
			text-decoration: underline;
		}
		padding-left: 30px;
		&::first-of-type {
			padding-left: 0;
		}
	}
`;

const MenuButton = styled.button`
	padding: 0;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	margin: 0 15px 0 0;
	cursor: pointer;

	@media (min-width: 1024px) {
		&.mobile {
			display: none;
		}
	}

	&:focus {
		outline: 1px solid transparent;
	}
	svg {
		height: 32px;
		width: 32px;
	}
`;

const HeaderComponent = ({ isHome }) => {
	const [navOpen, setNavOpen] = useState(false);

	return (
		<React.Fragment>
			{isHome ? (
				<HomeHeader>
					<TitleLink to='/'>Marquise Stillwell</TitleLink>
					<MenuButton onClick={() => setNavOpen(true)}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='44'
							height='44'
							viewBox='0 0 24 24'
							strokeWidth='1'
							stroke={'#f9fdf7'}
							fill='none'
							strokeLinecap='round'
							strokeLinejoin='round'>
							<path stroke='none' d='M0 0h24v24H0z' />
							<line x1='4' y1='8' x2='20' y2='8' />
							<line x1='4' y1='16' x2='20' y2='16' />
						</svg>
					</MenuButton>
				</HomeHeader>
			) : (
				<Header>
					<TitleLink to='/'>Marquise Stillwell</TitleLink>
					<Nav>
						<NavLink to='/ventures' activeClassName='active'>
							Ventures
						</NavLink>

						<NavLink to='/podcasts' activeClassName='active'>
							Podcast
						</NavLink>

						<NavLink to='/blog' activeClassName='active'>
							Blog
						</NavLink>

						<NavLink to='/press' activeClassName='active'>
							Press
						</NavLink>

						<NavLink to='/about' activeClassName='active'>
							About
						</NavLink>
					</Nav>
					<MenuButton className='mobile' onClick={() => setNavOpen(true)}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='44'
							height='44'
							viewBox='0 0 24 24'
							strokeWidth='1'
							stroke={'#131313'}
							fill='none'
							strokeLinecap='round'
							strokeLinejoin='round'>
							<path stroke='none' d='M0 0h24v24H0z' />
							<line x1='4' y1='8' x2='20' y2='8' />
							<line x1='4' y1='16' x2='20' y2='16' />
						</svg>
					</MenuButton>
				</Header>
			)}

			<MobileNav open={navOpen} onClick={() => setNavOpen(false)} />

			<IndexNav open={navOpen} onClick={() => setNavOpen(false)} />
		</React.Fragment>
	);
};

export default HeaderComponent;
