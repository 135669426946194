import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Close from '../images/close.svg';
import colors from './_colors.scss';

const IndexNav = styled.nav`
	display: none;

	@media (min-width: 1024px) {
		transition: opacity 400ms ease;
		display: initial;
		position: fixed;
		top: 0;
		min-width: 400px;
		width: 25%;
		bottom: 0;
		right: 0;
		pointer-events: none;
		background-color: ${props => props.background};
		border-left: 1px solid;
		z-index: 10;
		transform: translateX(100%);
		transition: transform 400ms;
		transition-timing-function: cubic-bezier(0.39, 0, 0.72, 0.12);
		padding: 45px 50px;

		text-align: right;

		&.open {
			transform: translateX(0);
			pointer-events: all;
		}
	}
`;
const NavHeader = styled.header``;

const NavLink = styled(Link)`
	display: block;
	color: inherit;
	text-decoration: none;
	font-size: 36px;
	padding: 10px 20px;

	&:first-of-type {
		margin-top: 40px;
	}

	&.active {
		text-decoration: underline;
	}

	@media (min-width: 1300px) {
		font-size: 48px;
	}
`;

const CloseButton = styled.button`
	padding: 0;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	margin: 0 15px 0 0;
	cursor: pointer;
	&:focus {
		outline: 1px solid transparent;
	}
`;

const CloseImage = styled.img`
	max-height: 32px;
`;

const HeaderComponent = ({ open, onClick }) => {
	return (
		<IndexNav className={open ? 'open' : ''} background={colors.background}>
			<NavHeader>
				<CloseButton onClick={onClick}>
					<CloseImage src={Close} alt={'Close Button'} />
				</CloseButton>
			</NavHeader>
			<NavLink onClick={onClick} to='/ventures' activeClassName='active'>
				Ventures
			</NavLink>

			<NavLink onClick={onClick} to='/podcasts' activeClassName='active'>
				Podcast
			</NavLink>

			<NavLink onClick={onClick} to='/blog' activeClassName='active'>
				Blog
			</NavLink>
			<NavLink onClick={onClick} to='/press' activeClassName='active'>
				Press
			</NavLink>
			<NavLink onClick={onClick} to='/about' activeClassName='active'>
				About
			</NavLink>
		</IndexNav>
	);
};

HeaderComponent.propTypes = {
	open: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default HeaderComponent;
