const React = require("react")
const Layout = require("./src/components/Layout/Layout").default

// Wrap every page with the main layout
exports.wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

console.log(`
Site Credit
===========
Development : https://buena-suerte.studio
`)
