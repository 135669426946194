import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';
import styled from 'styled-components';
import classNames from 'classnames';
import { useLocation } from '@reach/router';
import { SwitchTransition, Transition } from 'react-transition-group';

import './reset.css'
import './Layout.scss'

const TRANSITION_DURATION = 400

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
}

const Container = styled.main`
	margin-top: 57px;
	&.home {
		margin-top: 0;
	}
	@media (min-width: 1024px) {
		margin-top: 115px;
		&.home {
			margin-top: 0;
		}
	}
`

const Layout = ({ children, location }) => {
	const [isHome, setIsHome] = useState(true)
	console.log(location)

	const { pathname } = useLocation()

	useEffect(() => {
		setIsHome(pathname === '/')
		return () => {}
	}, [pathname])

	return (
		<React.Fragment>
			<Header isHome={isHome} />

			<SwitchTransition>
				<Transition key={pathname} mountOnEnter unmountOnExit appear timeout={TRANSITION_DURATION}>
					{status => (
						<Container
							className={classNames('', {
								home: isHome,
							})}
							style={{
								...TRANSITION_STYLES.default,
								...TRANSITION_STYLES[status],
							}}>
							{children}
						</Container>
					)}
				</Transition>
			</SwitchTransition>
			{!isHome && <Footer />}
		</React.Fragment>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
