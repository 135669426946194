import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

const Footer = styled.footer`
	display: flex;
	flex-direction: column;
	padding: 20px;
	margin-top: auto;
	border-top: 1px solid;
	background-color: #f9fdf7;
	z-index: 2;

	@media (min-width: 1024px) {
		padding: 45px 50px;
		flex-direction: row;
		justify-content: space-between;
	}
`;

const Copyright = styled.p``;

const EmailLink = styled.div`
	padding: 10px 0 0;
	a {
		color: inherit;
		text-decoration: none;
		border-bottom: 1px solid;
		transition: border-bottom 400ms ease;
		cursor: pointer;

		&:hover {
			border-color: transparent;
		}
	}

	@media (min-width: 1024px) {
		padding: 0;
	}
`;

const FooterComponent = () => {
	const data = useStaticQuery(graphql`
		{
			allSanityAbout {
				nodes {
					_rawEmail
				}
			}
		}
	`);

	const { _rawEmail: email } = data?.allSanityAbout?.nodes[0];

	return (
		<Footer>
			<Copyright>© {new Date().getFullYear()} Marquise Stillwell</Copyright>
			{email && (
				<EmailLink>
					<BlockContent blocks={email} />
				</EmailLink>
			)}
		</Footer>
	);
};

export default FooterComponent;
