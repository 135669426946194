import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Close from '../images/close.svg';
import { useLocation } from '@reach/router';
import colors from './_colors.scss';

const MobileNav = styled.nav`
	opacity: 0;
	transition: opacity 400ms ease;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	pointer-events: none;
	background-color: ${props => props.background};
	z-index: 10;

	&.open {
		opacity: 1;
		pointer-events: all;
	}

	@media (min-width: 1024px) {
		display: none;
	}
`;
const NavHeader = styled.header`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid;
`;

const HeaderTitle = styled(Link)`
	padding: 20px;
	color: inherit;
	text-decoration: none;
`;

const NavLink = styled(Link)`
	display: block;
	color: inherit;
	text-decoration: none;
	font-size: 2em;
	padding: 10px 20px;

	&:first-of-type {
		margin-top: 40px;
	}

	&.active {
		text-decoration: underline;
	}
`;

const CloseButton = styled.button`
	padding: 0;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	margin: 0 15px 0 0;
	&:focus {
		outline: 1px solid transparent;
	}
`;

const CloseImage = styled.img`
	max-height: 32px;
`;

const HeaderComponent = ({ open, onClick }) => {
	return (
		<MobileNav className={open ? 'open' : ''} background={colors.background}>
			<NavHeader>
				<HeaderTitle to='/'>Marquise Stillwell</HeaderTitle>
				<CloseButton onClick={onClick}>
					<CloseImage src={Close} alt={'Close Button'} />
				</CloseButton>
			</NavHeader>
			<NavLink to='/ventures' activeClassName='active'>
				Ventures
			</NavLink>

			<NavLink to='/podcasts' activeClassName='active'>
				Podcast
			</NavLink>

			<NavLink to='/blog' activeClassName='active'>
				Blog
			</NavLink>
			<NavLink to='/press' activeClassName='active'>
				Press
			</NavLink>
			<NavLink to='/about' activeClassName='active'>
				About
			</NavLink>
		</MobileNav>
	);
};

HeaderComponent.propTypes = {
	open: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default HeaderComponent;
